.proback {
  background: rgb(147, 220, 204);
  background: linear-gradient(
    45deg,
    rgba(147, 220, 204, 1) 49%,
    rgba(0, 174, 136, 1) 100%
  );
}
.eduback {
  background: rgb(147, 220, 204);
  /* background: linear-gradient(
    270deg,
    rgba(147, 220, 204, 1) 49%,
    rgba(0, 174, 136, 1) 100%
  ); */
}
