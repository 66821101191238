/* Add your custom styles here */
body {
  padding-top: 65px;
}

.header-hidden {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.navbar {
  transition: transform 0.2s ease-in-out;
}
